<template>
  <div class="elite_layout">
    <section class="el-container is-vertical">
      <header class="el-header" style="height: 60px">
        <div class="topNav">
          <ul
            role="menubar"
            class="el-menu-demo el-menu--horizontal el-menu"
            style="background-color: rgb(255, 255, 255)"
          >
            <div class="logo" tabindex="0">
              <img src="../../assets/images/images/logo.png" alt="" />
            </div>

            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/')"
            >
              首页
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item is-active"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/elite_position')"
            >
              职位
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/elite_company')"
            >
              企业
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item is-active"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/elite_resume')"
            >
              简历
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item"
              style="
                color: rgb(255, 153, 0);
                border-bottom-color: rgb(255, 153, 0);
                background: none rgb(255, 255, 255);
              "
              @click="$router.push('/elite_chat')"
            >
              聊天
            </li>
            <div v-if="weixin_nickname != null" class="menu_me" tabindex="0">
              <span class="el-popover__reference-wrapper"
                ><div
                  class="el-dropdown-link el-popover__reference"
                  aria-describedby="el-popover-5162"
                  tabindex="0"
                >
                  <span>{{ weixin_nickname }}</span
                  ><img :src="weixin_pic" />

                  <span> <a href="" @click="tuichu">退出</a></span>
                </div></span
              >
              <!----><!---->
            </div>

            <div class="menu_me" v-else>
              <div class="btn" @click="$router.push('/login')">企业登录</div>
              <div class="btnZP" @click="$router.push('/register')">
                我要招聘
              </div>
              <div class="btnZP" @click="$router.push('/elite_login')">
                我要找工作
              </div>
            </div>
          </ul>
        </div>
      </header>
      <main class="el-main">
        <div class="main">
          <div data-v-38a4ab5f="" class="onlineChat">
            <div class="chatConent">
              <div class="chat_left">
                <div class="title">最近联系人</div>
                <div class="scoll">
                  <div class="info" style="display: none">您有新消息了</div>

                  <div
                    class="character add_border"
                    v-for="(item, indexc) in chat_list"
                    :key="indexc"
                  >
                    <div
                      class="group"
                      :class="
                        'group' +
                        ((user_detail_id == item.user_detail_id) &
                        (jd_id == item.jd_id)
                          ? ' add_bg'
                          : '')
                      "
                      @click="set_msg(item.jd_id, item.user_detail_id)"
                    >
                      <img :src="item.company_logo" alt="" /><span
                        class="name"
                        >{{ item.company_name }}</span
                      >
                      <div class="newinfo">
                        {{ item.content }}
                      </div>
                      <div class="time">{{ item.time }}</div>
                    </div>
                  </div>
                  <div class="more" v-if="is_hide == false">
                    <button
                      type="button"
                      class="el-button el-button--text"
                      @click="set_chat_list"
                    >
                      <!----><!----><span>加载更多</span>
                    </button>
                  </div>

                  <div class="more" v-else>
                    <button
                      disabled="disabled"
                      type="button"
                      class="el-button el-button--text is-disabled"
                    >
                      <!----><!----><span>没有更多了</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="chat_right">
                <div class="chatContent">
                  <div class="jd_conent">
                    <div class="chuangkou">聊天窗口</div>
                  </div>
                  <div class="cencont">
                    <div class="wrap" id="words">
                      <div class="chat-div" id="chat-div">
                        <div class="chat-content" id="chat-content">
                          <!-- recordContent 聊天记录数组-->
                          <div v-for="(itemc, indexc) in msgList" :key="indexc">
                            <!-- 对方 -->
                            <div class="word" v-if="itemc.speaker == 'server'">
                              <img :src="company_data.company_logo" />
                              <div class="info">
                                <p class="time">
                                  {{ company_data.company_name }}
                                  {{ itemc.time }}
                                </p>
                                <div class="info-content">
                                  {{ itemc.content }}
                                </div>
                              </div>
                            </div>
                            <!-- 我的 -->
                            <div class="word-my" v-else>
                              <div class="info">
                                <p class="time">
                                  {{ itemc.time }}
                                </p>
                                <div class="info-content">
                                  {{ itemc.content }}
                                </div>
                              </div>
                              <img :src="weixin_pic" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="send_div">
                        <el-input
                          style=""
                          v-model="add_str"
                          class="input-with-select"
                        >
                          <el-button
                            @click="chatadd"
                            slot="append"
                            style="color: #ff0000"
                            >发送</el-button
                          >
                        </el-input>
                      </div>
                    </div>

                    <!--  <p class="tishi">回车键会折行，发送请点击发送按钮！</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { chat_list, chat_detail, chat_add } from "@/api/login.js";
import $ from "jquery";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      weixin_nickname: sessionStorage.getItem("weixin_nickname"),
      weixin_pic: sessionStorage.getItem("weixin_pic"),
      page: 1, //聊天列表第几页
      chat_list: [], //聊天列表

      user_detail_id: "", //公司id
      jd_id: "", //职位id
      company_data: [],
      msgList: [], //聊天详情列表
      jd_item: {}, //职位信息

      msg_max_id: 0,

      add_str: "", //发送内容

      is_hide: false,

      timer: 0, //聊天详情 auto
      timer_list: 0, //聊天列表 auto
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //退出登录
    tuichu: function () {
      sessionStorage.clear();
      this.$router.replace("/elite_login");
    },

    set_chat_list: function () {
      console.log("set_chat_list", this.page);
      this.page = this.page + 1;
      this.get_chat_list();
    },

    //获取聊天列表 累加
    get_chat_list: function () {
      console.log("page:", this.page);
      let that = this;
      chat_list({
        page: that.page,
      }).then((res) => {
        console.log("获取聊天列表", res.data);

        if (res.data.length == 0) {
          this.page = this.page - 1;
          this.is_hide = true;
        } else {
          that.chat_list = that.chat_list.concat(res.data);
          console.log(res);
        }
      });
    },

    //获取聊天列表 不累加
    get_chat_list2: function () {
      console.log("page:", 1);
      let that = this;
      chat_list({
        page: that.page,
      }).then((res) => {
        console.log("获取聊天列表 不累加", res.data);

        that.chat_list = res.data;
        console.log(res);
      });
    },

    //"set_msg(item.jd_id,item.user_detail_id)
    set_msg: function (jd_id, user_detail_id) {
      clearInterval(this.timer);

      let that = this;
      console.log("点击参数：", jd_id, user_detail_id);
      this.jd_id = jd_id;
      this.user_detail_id = user_detail_id;
      that.get_msg_detail();

      that.timer = setInterval(this.get_msg_detail, 5000);
    },

    get_msg_detail: function () {
      //聊天详情

      if (typeof this.user_detail_id === "undefined") {
        return;
      }

      chat_detail({
        user_detail_id: this.user_detail_id,
        jd_id: this.jd_id,
      }).then((res) => {
        console.log("获取聊天详情", res.data);

        this.msgList = res.data.chat_list;
        this.jd_item = res.data.jd_item;
        this.company_data = res.data.company_data;

        this.msg_max_id = res.data.msg_max_id;

        //页面渲染完成后执行 开始 代码
        this.$nextTick(function () {
          this.myscroll();
        });

        console.log(this.msgList);
      });
    },
    //发送消息
    chatadd() {
      if (this.add_str != "") {
        chat_add({
          jd_id: this.jd_id,
          content: this.add_str,
          user_detail_id: this.user_detail_id,
        }).then((res) => {
          this.add_str = "";

          //把最新消息添加进去
          this.get_msg_detail();
          this.$message.success("发送成功");
          // this.chatItems.push({
          // 	'content': [
          // 		{
          // 			'span': 24,
          // 			'url': 'www.1.com',
          // 		},
          // 	]
          // },)

          // this.myscroll();
        });
      }
    },
    //控制滚动条
    myscroll() {
      var div = document.getElementById("chat-div");
      var div_c = document.getElementById("chat-content");

      console.log(div_c.scrollHeight, div.scrollHeight);
      div.scrollTop = div_c.scrollHeight;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //没有登录 请重新登录
    if (sessionStorage.getItem("token") == null) {
      this.$message({
        message: "请您先登录！",
        type: "warning",
      });

      this.$router.replace("/elite_login");
      return;
    }

    //说是在其他页面点击聊一聊过来的
    var jd_id = this.$route.query.jd_id;
    var user_detail_id = this.$route.query.user_detail_id;

    if (user_detail_id != "") {
      let that = this;
      console.log("点击参数：", jd_id, user_detail_id);
      this.jd_id = jd_id;
      this.user_detail_id = user_detail_id;
      that.get_msg_detail();

      that.timer = setInterval(this.get_msg_detail, 5000);
    }

    console.log("11111111111111111111111111");
    this.get_chat_list2();

    this.timer_list = setInterval(this.get_chat_list2, 5000);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer_list);
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.topNav {
  width: 100%;
  height: 100%;
  background: #fff;
}
.topNav .el-menu-item:hover {
  outline: 0 !important;
  background: #fff !important;
}
.topNav .el-menu-item.is-active {
  background: #fff !important;
}
.el-menu-demo {
  width: 1200px;
  margin: auto;
}
.logo {
  float: left;
  height: 44px;
  padding-top: 16px;
  margin-right: 20px;
}
.logo > img {
  height: 26px;
}
.menu_me {
  float: right;
  width: 365px;
}
.el-dropdown-link {
  float: right;
  height: 60px;
  overflow: hidden;
}
.el-dropdown-link img {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 5px;
}
.el-dropdown-link span {
  display: block;
  float: left;
  height: 60px;
  line-height: 60px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 700;
}
.el-dropdown-link i {
  float: left;
  display: block;
  width: 20px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-top: 5px;
}
.nav_con > p {
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.nav_con > p > a {
  color: #000;
}
.nav_con > p > a:hover,
.nav_con > p:hover {
  color: #f90;
}
.btn {
  background-color: rgba(253, 148, 15, 0.8) !important;
  border-radius: 6px;
}
.btn,
.btnZP {
  float: right;
  width: 100px;
  text-align: center;
  line-height: 38px;
  margin-top: 12px;
  margin-right: 20px;
  -webkit-box-shadow: hsla(0, 0%, 83.9%, 0.8) 2px 4px 1px 1px;
  box-shadow: 2px 4px 1px 1px hsla(0, 0%, 83.9%, 0.8);
  cursor: pointer;
}
.btnZP {
  border: 1px solid rgba(253, 148, 15, 0.8);
  border-radius: 6px;
}

.select_city {
  float: left;
  line-height: 61px;
  padding: 0 12px;
  font-size: 12px;
  color: #b47009;
  cursor: pointer;
}
.ziuzhiComponents {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.zhiqiu {
  width: 436px;
  height: 276px;
  position: absolute;
  top: 50%;
  margin-top: -200px;
  left: 50%;
  margin-left: -230px;
  background: #fff;
  padding: 12px;
  border-radius: 12px;
}
.zhiqiu > .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.zhiqiu > .item > p {
  float: left;
  width: 200px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #e6e4e4;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin: 14px auto;
  color: #202020;
}
.zhiqiu > .item > .index,
.zhiqiu > .item > p:hover {
  font-weight: 700;
  border-color: #f5ae55;
}
.header {
  padding: 0 12px;
  margin-bottom: 25px;
}
.header h4 {
  margin: 0 0;
}
.header p {
  margin: 6px 0;
  font-size: 14px;
  color: #8d92a1;
}
.footer {
  text-align: right;
  padding-right: 20px;
}
.elite_layout {
  min-width: 1250px;
  position: relative;
}
.main {
  margin: auto;
}
.el-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 0;
  z-index: 10;
}
.el-main {
  padding: 0;
}

.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.btn {
  color: #fff !important;
  background-color: #ff8f00 !important;
  border: none !important;
  width: 100px;
}
.el-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: -40px;
}

.scroll {
  max-height: 500px;
  border-bottom: 4px solid #858585;
  overflow-y: auto;
}

.el-select {
  width: 80% !important;
}
.multi {
  width: 80% !important;
}
.multi div {
  width: 10% !important;
}

p {
  margin: 0;
}
.elite_resume {
  width: 800px;
  margin: auto;
  padding-top: 20px;
}
.card_margin {
  margin: 12px 0;
  position: relative;
}
.card_margin_a {
  margin: 12px 0;
  height: 150px;
}
.resume {
  width: 800px;
  margin: auto;
  margin-top: 56px;
  background: #fff;
  border-radius: 12px;
  padding: 20px 0;
}
.resume .el-card__body {
  padding: 12px 0;
}
.resume > div {
  position: relative;
}
.resume > div:nth-child(2) {
  margin-top: 12px;
}
.me {
  height: 226px;
}

.work_experience {
  height: 150px;
}
.educationalExperience {
  height: 80px;
}
.touxiang {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 12px;
  left: 27px;
  cursor: pointer;
}
.touxiang img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.wu {
  background: #f8f8f8;
  color: #e9e9e9;
}
.shang,
.wu {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  line-height: 120px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.shang {
  display: none;
  background: rgba(51, 51, 51, 0.3803921568627451);
  color: #fff;
}
.shang .el-upload {
  width: 100%;
}
.touxiang > img {
  width: 120px;
  height: 120px;
}
.touxiang:hover .shang {
  display: block;
}
.xinxi {
  width: 610px;
  position: absolute;
  left: 180px;
  height: 200px;
}
.xinxi > p {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
}
.xinxi > p:first-child {
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}
.xinxi > p:nth-child(2),
.xinxi > p:nth-of-type() {
  font-size: 14px;
}
.xinxi > p:nth-child(2) > span {
  padding: 6px 6px;
}
.xinxi > p:nth-child(2) > span:first-child {
  padding-left: 0;
}
.xinxi > p:nth-child(2) > .span_border {
  padding: 0 12px;
  border-right: none;
}
.bianji {
  position: absolute;
  top: 0;
  right: 20px;
}
.title {
  width: 756px;
  font-size: 18px;
  margin: auto;
  margin-top: 12px;
  color: #000;
  font-weight: 700;
  padding: 6px;
  border-left: 4px solid #c54646;
}
.conent {
  padding-left: 20px;
  color: #ccc;
}
.conent > p {
  padding: 10px 0;
  color: #585858;
}
.conent > p > span {
  padding: 0 12px;
  border-right: 1px solid #ccc;
}

.conent > p > span:last-child {
  border-right: none;
}
.work_experience > div > p > span > span {
  padding: 0 12px !important;
  border-right: 1px solid #ccc;
}
.conent > p > .span_border {
  padding: 0 12px;
  border-right: none;
}
.pingjia {
  min-height: 80px;
  text-indent: 35px;
  padding: 12px;
  color: #484848;
  line-height: 24px;
}
.lianxi span {
  display: inline-block;
}
.lianxi:last-child span {
  display: inline-block;
  text-align: left;
}
.work_time {
  padding: 12px 0;
  border-bottom: 1px solid #ccc;
}
.work_time:last-child {
  padding: 12px 0;
  border-bottom: none;
}
.kong_resume {
  width: 1200px;
  margin: auto;
  height: 600px;
  background: #fff;
  line-height: 600px;
  margin-top: 56px;
  color: #111;
  font-size: 20px;
  text-align: center;
}
.kong_resume:hover {
  color: #f90;
  font-weight: 700;
  cursor: pointer;
}
.caozuo {
  padding: 0 12px;
  overflow: hidden;
}
.caozuo .el-switch__label.is-active {
  color: #2d7e08;
  font-size: 18px;
}
.resume_shelves {
  float: right;
}
.educationalExperience {
  min-height: 60px;
}
.checkbox {
  float: right;
  margin-top: 10px;
}
.checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
.checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  color: #000;
  background-color: #ff8f00;
  border-color: #ff8f00;
}
.scroll {
  max-height: 500px;
  border-bottom: 4px solid #858585;
  overflow-y: auto;
}
.title_position {
  position: absolute;
  top: 0;
}
.career_progress {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
}

.comment {
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #c0c4cc;
  cursor: pointer;
  padding: 0 10px;
}
.bounce,
.comment {
  float: right;
  margin-right: 10px;
}
.get-btn .el-button.el-button--text {
  padding: 12px 10px;
  color: #fff;
}
.get-btn .el-button.el-button--text.el-dropdown__caret-button {
  padding: 12px 5px;
  color: #fff;
}
.bounce .el-button.el-button--text {
  padding: 12px 10px;
  color: #fff;
  background-color: #ceb461;
}
.bounce .el-button.el-button--text.el-dropdown__caret-button {
  padding: 12px 5px;
  color: #fff;
  background-color: #ceb461;
}

.onlineChat {
  width: 1200px;
  margin: auto;
  height: 850px;
  padding-top: 80px;
  padding-bottom: 3px;
}
.chatConent {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #fff;
}
.chatConent > div {
  float: left;
}
.chat_left {
  width: 249px;
  border-right: 1px solid #ccc;
  background: #fff;
}
.chat_right {
  width: calc(100% - 251px);
  background: #fff;
}
.title {
  font-size: 14px;
  color: #6b6b6b;
  text-align: center;
  border-bottom: 2px solid #eaeaea;
  padding: 11px 0;
}
.character {
  width: 100%;
  height: 80px;
  position: relative;
  cursor: pointer;
}
.jd_conent {
  border-bottom: 1px solid #aaa;
}
.character > div {
  height: 80px;
}
.dian {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 6px;
  right: 6px;
  border-radius: 50%;
  background: #dd0505;
  color: #fff;
}
.add_bg {
  background: rgba(255, 153, 0, 0.2);
}
.scoll {
  overflow: auto;
  height: 800px;
  position: relative;
}
.scoll::-webkit-scrollbar {
  width: 6px;
}
.group > img,
.zhiliao > img {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  left: 5px;
  border-radius: 50%;
}
.group > .name,
.zhiliao > .name {
  display: block;
  height: 20px;
  position: absolute;
  top: 30%;
  left: 65px;
  margin-top: -10px;
  color: #242424;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
}
.group > .xinxi,
.zhiliao > .xinxi {
  font-size: 12px;
  color: #727272;
  position: absolute;
  left: 65px;
  top: 50%;
}
.zhiliao > .qiwang {
  font-size: 12px;
  color: #9c9c9c;
  position: absolute;
  left: 120px;
  top: 46%;
}
.group > .newinfo,
.zhiliao > .newinfo {
  font-size: 12px;
  color: #3a3a3a;
  position: absolute;
  left: 65px;
  top: 60px;
}
.zhiliao > .qiwang > span,
.zhiliao > .xinxi > span {
  padding: 0 6px;
  border-right: 1px solid #ccc;
}
.zhiliao > .qiwang > span:first-child,
.zhiliao > .xinxi > span:first-child {
  padding-left: 0;
}
.zhiliao > .qiwang > span:last-child,
.zhiliao > .xinxi > span:last-child {
  border: none;
}
.group > .time,
.zhiliao > .time {
  position: absolute;
  top: 65px;
  line-height: 20px;
  height: 20px;
  margin-top: -10px;
  right: 10px;
  font-size: 12px;
  color: #686868;
}
.add_border {
  border-bottom: 1px solid #ccc;
}
.cencont {
  position: relative;
  height: 800px;
}
.cencont > .show {
  height: 626px;
  overflow: auto;
}
.cencont > .show::-webkit-scrollbar {
  width: 6px;
}
.cencont > .shuru {
  height: 117px;
}
.fasong {
  width: 10%;
  float: left;
  height: 116px;
  padding: 0;
}
.fasong span {
  color: #ccc;
  font-size: 14px;
  padding: 0 12px;
}
.fasong .el-button {
  width: 100%;
  height: 100%;
  background: #fda623;
  border-color: #fda623;
  color: #fff;
}
.fasong .el-button:hover {
  color: #fff;
  background: rgba(253, 166, 35, 0.9);
  border-color: #fda623;
}
.show > div > div {
  width: 98%;
  margin: auto;
  padding: 12px 0;
}
.show > div > div > div {
  padding: 6px 0;
  overflow: hidden;
  margin-right: 12px;
}
.show > div > div > div > .left {
  float: left;
  max-width: 80%;
  padding: 6px 20px;
  margin-left: 20px;
  background: #e0e0e0;
  border-radius: 0 8px 8px 8px;
}
.show > div > div > div > .right {
  float: right;
  max-width: 80%;
  padding: 6px 20px;
  margin-right: 20px;
  background: #fda623;
  border-radius: 8px 0 8px 8px;
}
.show > div > .left,
.show > div > .right {
  overflow: hidden;
}
.show > div > .left > div {
  float: left;
}
.show > div > .left > div img {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.show > div > .left > div div {
  margin-left: 6px;
  float: left;
}
.show > div > .left > div div p {
  font-size: 14px;
}
.show > div > .left > div div p span {
  padding-left: 6px;
  font-size: 12px;
  color: #ccc;
}
.show > div > .left > div div > div > p {
  max-width: 80%;
  padding: 6px 20px;
  background: #e0e0e0;
  border-radius: 0 8px 8px 8px;
}
.show > div > .right > div {
  float: right;
}
.show > div > .right > div img {
  float: right;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.show > div > .right > div div {
  margin-right: 6px;
  float: right;
}
.show > div > .right > div div p {
  text-align: right;
  font-size: 14px;
}
.show > div > .right > div div p span {
  font-size: 12px;
  color: #ccc;
  padding-right: 6px;
}
.show > div > .right > div div > div > p {
  max-width: 80%;
  padding: 6px 20px;
  background: #fda623;
  border-radius: 8px 0 8px 8px;
}
.show > div > .left > div > .jd_conent {
  color: #5f5f5f;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
  padding-left: 20px;
}
.jd_conent > .jd {
  font-size: 16px;
  line-height: 41px;
}
.jd_conent > .jd span {
  padding: 0 6px;
}
.jd_conent > .chuangkou {
  text-align: center;
  font-weight: 700;
  line-height: 41px;
}
.jd_conent > span:first-child {
  font-weight: 700;
}
.jd_conent > span:nth-child(2) {
  color: #fda623;
}
.jiaoHuan {
  height: 30px;
  border-top: 2px solid hsla(0, 0%, 80%, 0.5);
  margin-bottom: 2px;
  overflow: hidden;
}
.jiaoHuan > p {
  float: left;
  padding: 0 0;
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  margin: 0 12px;
}
.jiaoHuan > p > span {
  font-size: 25px;
  color: #9e9b9b;
  cursor: pointer;
}
.kongcontacts_list {
  line-height: 800px;
  font-size: 14px;
  text-align: center;
  color: #ccc;
}
.kongchat_conent {
  line-height: 60px;
  font-size: 14px;
  text-align: center;
  color: #ccc;
}
.jd_conentKong {
  line-height: 626px;
  padding-left: 20px;
  font-size: 20px;
  text-align: center;
  color: #ccc;
  border-bottom: 1px solid #ccc;
}
.show p {
  padding: 0;
  margin: 2px;
  font-size: 16px;
  color: #313131;
}
.tishi {
  text-align: right;
  margin: 0;
  font-size: 12px;
  color: #959595;
  line-height: 23px;
  font-weight: 700;
}
.hr {
  text-align: center;
  overflow: hidden;
  font-size: 14px;
  color: #ccc;
  margin-top: 30px;
}
.hr > span {
  padding: 4px 6px;
  background: hsla(0, 0%, 87.8%, 0.5);
  color: #5d5d5d;
}
.more {
  line-height: 50px;
  text-align: center;
}

.mytitle {
  text-align: center;
}
.chat-div {
  border: 10px solid;
  margin: 0 auto;
  width: 600px;

  height: 600px;
  overflow: auto;

  background-color: #f9f9f9;
}
.send_div {
  bottom: 0px;
  width: 620px;

  margin: 0 auto;

  margin-top: 10px;
}
.chat-content {
  width: 93%;
  padding: 20px;
  .word {
    display: flex;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      margin-left: 10px;
      .time {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.8);
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-top: -5px;
      }
      .info-content {
        padding: 10px;
        font-size: 14px;
        background: #fff;
        position: relative;
        margin-top: 8px;
        word-break: break-all;
      }
      //小三角形
      .info-content::before {
        position: absolute;
        left: -8px;
        top: 8px;
        content: "";
        border-right: 10px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }
  }

  .word-my {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      width: 90%;
      margin-left: 10px;
      text-align: right;
      .time {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.8);
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-top: -5px;
        margin-right: 10px;
      }
      .info-content {
        max-width: 70%;
        padding: 10px;
        font-size: 14px;
        float: right;
        margin-right: 10px;
        position: relative;
        margin-top: 8px;
        background: #a3c3f6;
        text-align: left;
        word-break: break-all;
      }
      //小三角形
      .info-content::after {
        position: absolute;
        right: -8px;
        top: 8px;
        content: "";
        border-left: 10px solid #a3c3f6;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }
  }
}

.fanhui {
  font-size: 20px;
  margin-left: 200px;
  margin-top: 80px;
}

.add_bg {
  background: rgba(255, 153, 0, 0.2);
}
</style>
